import React from "react";
import "../css/Footer.scss";

function Footer() {
  return (
    <div className="footer-section w-100 d-flex justify-content-center py-5">
      <div className="d-flex flex-column">
        <img src="/igly-dark.svg" alt="" width="110" height="auto"></img>
        <span>Igly Technologies © 2022</span>
      </div>
    </div>
  );
}

export default Footer;
