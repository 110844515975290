import React from "react";

function SecondSection() {
  return (
    <div className="second-section row">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="width-second-section">
          <h1 className="fw-bold">Lo que debes saber de la digitalización</h1>
          <div className="container-fluid">
            <div className="row mt-5">
              <div className="col-xl">
                <article className="information">
                  <h2 className="title">¿Por qué debería digitalizarme?</h2>
                  <p className="info">
                    Alguien que ofrece servicios digitales no solo mejora sus
                    procesos de negocio, sino también genera un beneficio para
                    los consumidores de su producto, ya que mejora notablemente
                    el tiempo y la eficiencia de los resultados y procesos que
                    definen su entidad.
                  </p>
                </article>
              </div>
              <div className="col-xl">
                <article className="information">
                  <h2 className="title">
                    ¿Que servicios pueden ser digitalizables?
                  </h2>
                  <p className="info">
                    Cualquier necesidad tiene una implementación tecnológica hoy
                    en día, y en la mayoría de casos es solo llevarlo a la
                    práctica, no sin antes realizar un análisis para detectar
                    las necesidades de tu negocio o empresa.
                  </p>
                </article>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xl">
                <article className="information">
                  <h2 className="title">
                    ¿Qué oportunidades de negocio tengo digitalizándome?
                  </h2>
                  <p className="info">
                    Existen muchas formas de ofrecer servicios digitales y abrir
                    camino a nuevas areas de negocio con la ayuda de la
                    tecnología, solo es cuestión de identificarlas y aplicarlas
                    a tu flujo principal.
                  </p>
                </article>
              </div>
              <div className="col-xl">
                <article className="information">
                  <h2 className="title">¿Es cara la digitalización?</h2>
                  <p className="info">
                    El costo variará dependiendo del servicio tecnológico
                    ofrecido, pero ya que cada solución y necesidad es diferente
                    para cada entidad, existe una opción flexible de costos para
                    tí.
                  </p>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondSection;
