import React from "react";

function ThirdSection() {
  return (
    <div className="about-us-third-section container-fluid d-flex justify-content-center align-items-center">
      <div className="width-about-us-ts">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h1 className="fw-bold pt-3">Valores de la compañía</h1>
          <p className="pt-3 text-center">
            En Igly, creemos en la importancia de construir un mundo mejor a
            través de nuestras acciones y servicios.
          </p>
          <div className="py-5 w-75">
            <div className="row">
              <div className="col-lg-6 text-center px-5 mb-5">
                <img
                  className="main-image"
                  src="/innovation.svg"
                  width="80"
                  height="80"
                />
                <h2>Innovación</h2>
                <p className="pt-3">
                  Adaptamos y evolucionamos, creando soluciones tecnológicas que
                  responden a las necesidades actuales.
                </p>
              </div>
              <div className="col-lg-6 text-center px-5 mb-5">
                <img
                  className="main-image"
                  src="/integrity.svg"
                  width="80"
                  height="80"
                />
                <h2>Integridad</h2>
                <p className="pt-3">
                  Operamos con honestidad y transparencia en todas nuestras
                  interacciones.
                </p>
              </div>
              <div className="col text-center px-5 mb-5">
                <img
                  className="main-image"
                  src="/collaboration.svg"
                  width="80"
                  height="80"
                />
                <h2>Colaboración</h2>
                <p className="pt-3">
                  Fomentamos un entorno de trabajo en equipo y colaboración.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 text-center px-5 mb-5">
                <img
                  className="main-image"
                  src="/empowering.svg"
                  width="80"
                  height="80"
                />
                <h2>Empoderamiento</h2>
                <p className="pt-3">
                  Valoramos el crecimiento y desarrollo de nuestro equipo para
                  alcanzar su máximo potencial.
                </p>
              </div>
              <div className="col-lg-6 text-center px-5 mb-5">
                <img
                  className="main-image"
                  src="/excellence.svg"
                  width="80"
                  height="80"
                />
                <h2>Excelencia</h2>
                <p className="pt-3">
                  Nos esforzamos por superar las expectativas y ofrecer la más
                  alta calidad en todo lo que hacemos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdSection;
