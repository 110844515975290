import { useState } from "react";
import Banner from "./Banner";
import "../css/NavBar.scss";

function NavBar() {
  const isActive = (path: string) => {
    return window.location.pathname === path;
  };

  return (
    <>
      <Banner />
      <nav className="navbar navbar-expand-lg navbar-light d-flex w-100 p-4">
        <a className="navbar-brand" href="#">
          <a href="/">
            <img src="/igly.svg" alt="" width="110" height="auto"></img>
          </a>
        </a>
        <ul className="list-unstyled mb-0 ms-5 d-flex">
          <li className="me-5">
            <a
              href="/ourservices"
              className={`${
                isActive("/ourservices") || isActive("/ourservices/saas")
                  ? "active-option"
                  : "hover-option"
              }`}
            >
              Nuestros servicios
            </a>
          </li>
          <li className="me-5">
            <a
              href="/aboutus"
              className={`${
                isActive("/aboutus") ? "active-option" : "hover-option"
              }`}
            >
              Acerca de
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default NavBar;
