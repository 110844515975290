import React from "react";

function FirstSection() {
  return (
    <>
      <div className="about-us-content">
        <div className="row">
          <div className="col-xl-7 d-flex justify-content-end align-items-center">
            <h1 className="fw-bold">
              Inspirando innovación para moldear un futuro digital más
              inteligente y eficiente.
            </h1>
          </div>
          <div className="col-xl-5 d-flex align-items-center">
            <img className="rounded-image" src="/igly_conference.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default FirstSection;
