import React from "react";
import FirstSection from "./FirstSection";
import "./../../css/AboutUs.scss";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";

function AboutUs() {
  return (
    <>
      <div className="about-us-first-section container-fluid d-flex flex-column justify-content-center align-items-center">
        <FirstSection />
      </div>
      <SecondSection />
      <ThirdSection />
    </>
  );
}

export default AboutUs;
