import React, { useState, useEffect } from "react";

interface MobileNavBarProps {
  toggleMenu: (value: boolean) => void;
}

const MobileNavBar: React.FC<MobileNavBarProps> = ({ toggleMenu }) => {
  function openNav() {
    const element = document.getElementById("mySidenav");

    if (element) {
      element.style.width = "250px";
    }
  }

  function closeNav() {
    const element = document.getElementById("mySidenav");
    if (element) {
      element.style.width = "0";
    }
  }

  const openMobileNav = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDimentions();
    openNav();
    toggleMenu(true);
    disableScroll();
  };

  const setDimentions = () => {
    const htmlElement = document.getElementById("sidenav-wrapper");

    if (htmlElement) {
      htmlElement.style.height = "100%";
      htmlElement.style.backgroundColor = "#000000c9";
    }
  };

  const closeMobileNav = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    resetDimentions();
    closeNav();
    enableScroll();
  };

  const resetDimentions = () => {
    const htmlElement = document.getElementById("sidenav-wrapper");

    if (htmlElement) {
      htmlElement.style.width = "100%";
      htmlElement.style.height = "auto";
      htmlElement.style.backgroundColor = "#ffffff";
    }
  };

  let scrollPosition = 0;

  function disableScroll() {
    scrollPosition = window.scrollY || document.documentElement.scrollTop;

    const containerElement = document.getElementById("app-container");
    containerElement?.classList.add("scroll-disabled");

    if (containerElement) {
      containerElement.style.top = `-${scrollPosition}px`;
    }

    const mnvc = document.getElementById("mobile-navbar-container");

    if (mnvc) {
      mnvc.style.top = `${scrollPosition}px`;
    }
  }

  function enableScroll() {
    const containerElement = document.getElementById("app-container");
    containerElement?.classList.remove("scroll-disabled");

    // Guarda el comportamiento de scroll actual
    const scrollBehaviorOriginal =
      document.documentElement.style.scrollBehavior;

    // Desactiva el desplazamiento suave
    document.documentElement.style.scrollBehavior = "auto";

    // Posiciona el scroll inmediatamente a la posición deseada
    window.scrollTo(0, scrollPosition);

    // Restablece el comportamiento original del scroll
    document.documentElement.style.scrollBehavior = scrollBehaviorOriginal;

    const mnvc = document.getElementById("mobile-navbar-container");

    if (mnvc) {
      mnvc.style.top = "auto";
    }
  }

  return (
    <div id="mobile-navbar-container" className="mobile-navbar-container w-100">
      <div id="sidenav-wrapper">
        <div id="mySidenav" className="sidenav">
          <div className="openedMenu d-flex align-items-center py-2 ps-4">
            <div className="burguer" onClick={closeMobileNav}>
              <a href="#" className="text-decoration-none">
                &#9776;
              </a>
            </div>
            <a href="/">
              <img
                src="/igly-dark.svg"
                alt="igly logo"
                width="90"
                height="auto"
                className="ms-3"
              ></img>
            </a>
          </div>
          <div className="overflow-hidden pt-3">
            <ul className="list-unstyled">
              <li className="p-3">
                <div className="item px-3 py-1 d-flex">
                  <img
                    src="/services-icon.svg"
                    alt=""
                    width="25"
                    height="auto"
                    className="me-1"
                  ></img>
                  <a href="/ourservices" className="text-decoration-none">
                    Servicios
                  </a>
                </div>
              </li>
              <li className="p-3">
                <div className="item px-3 py-1 d-flex">
                  <img
                    src="/aboutus-icon.svg"
                    alt=""
                    width="25"
                    height="auto"
                    className="me-1"
                  ></img>
                  <a href="/aboutus" className="text-decoration-none">
                    Acerca de
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="closedMenu d-flex align-items-center py-2 ps-4">
          <div className="burguer" onClick={openMobileNav}>
            <a href="#" className="text-decoration-none">
              &#9776;
            </a>
          </div>
          <a href="/">
            <img
              src="/igly.svg"
              alt="igly logo"
              width="90"
              height="auto"
              className="ms-3"
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileNavBar;
