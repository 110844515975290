import React from "react";

function FourSection() {
  return (
    <div className="four-section d-flex flex-column align-items-center justify-content-center">
      <div className="width-four-section">
        <div className="container pt-5">
          <h1 className="fw-bold">Factores que benefician a tus servicios</h1>
          <div className="d-flex align-items-center py-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                className="main-image"
                src="/cloud.svg"
                width="80"
                height="80"
              />
            </div>
            <div className="benefit-section ps-4">
              <h3 className="service-title fw-bold">Servicios en la nube</h3>
              <p className="legend text-size-5 font-weight-light line-height-6 pt-2">
                Aquí se da lugar a tus soluciones tecnológicas a través de
                productos digitales y accesibles a través de internet.
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center py-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                className="main-image"
                src="/code.svg"
                width="80"
                height="80"
              />
            </div>
            <div className="benefit-section ps-4">
              <h3 className="service-title fw-bold">Fácil acceso</h3>
              <p className="legend text-size-5 font-weight-light line-height-6 pt-2">
                Da igual donde te encuentres, ofrece tus productos a cualquier
                persona en el mundo de manera fácil y segura.
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center py-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                className="main-image"
                src="/distributed.svg"
                width="80"
                height="80"
              />
            </div>
            <div className="benefit-section ps-4">
              <h3 className="service-title fw-bold">
                Incremento de productividad
              </h3>
              <p className="legend text-size-5 font-weight-light line-height-6 pt-2">
                La automatización permite realizar tareas de forma más rápida y
                eficiente con un menor grado de errores.
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center py-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                className="main-image"
                src="/deployment.svg"
                width="80"
                height="80"
              />
            </div>
            <div className="benefit-section ps-4">
              <h3 className="service-title fw-bold">Rápida distribución</h3>
              <p className="legend text-size-5 font-weight-light line-height-6 pt-2">
                Lanza rápidamente actualizaciones y mejoras en tus servicios en
                un tiempo mínimo para el disfrute de tus usuarios.
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center py-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                className="main-image"
                src="/save-time.svg"
                width="80"
                height="80"
              />
            </div>
            <div className="benefit-section ps-4">
              <h3 className="service-title fw-bold">Ahorro de tiempo</h3>
              <p className="legend text-size-5 font-weight-light line-height-6 pt-2">
                Deja el trabajo pesado a la tecnología y enfócate en nuevas
                oportunidades de negocio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FourSection;
