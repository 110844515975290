import React, { useState, ChangeEvent } from "react";
import FormInput from "../forms/FormInput";

function FirstSection() {
  type InputType = {
    id: number;
    name: string;
    type: string;
    errorMessage: string;
    placeholder: string;
  };

  const inputs: InputType[] = [
    {
      id: 1,
      name: "email",
      type: "text",
      errorMessage: "",
      placeholder: "Correo electronico",
    },
  ];

  type ErrorsType = {
    [key: string]: string;
  };

  const [errors, setErrors] = useState<ErrorsType>({
    email: "",
  });

  const sendRequest = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const data = new FormData(form);

    const email = data.get("email") as string;

    if (!validateEmail(email)) {
      const emailErrorElement = document.getElementById("email_error");

      if (emailErrorElement != null) {
        setErrors((prev) => ({
          ...prev,
          ["email"]: "El email es inválido",
        }));
      }
    }
  };

  const validateEmail = (email: string): boolean => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  return (
    <div className="first-section row">
      <div className="form col-xl-7 d-flex justify-content-center align-items-center">
        <div className="width-form">
          <h1 className="fw-bold">
            La tecnología no es un tema de lujos sino de necesidades
          </h1>
          <h2 className="pt-5">
            Infórmate acerca de cómo la tecnología ha cambiado la manera de
            ofrecer servicios
          </h2>
        </div>
      </div>
      <div className="image col-xl-5 d-flex justify-content-center align-items-center">
        <div className="width-image">
          <img src="/complement.png" alt=""></img>
        </div>
      </div>
    </div>
  );
}

export default FirstSection;
