import React from "react";
import "./../../css/Timeline.scss";

function Timeline() {
  return (
    <div className="timeline-section">
      <main>
        <p className="text-end">
          <h2>Análisis</h2>
          <div>
            Evaluamos detalladamente tus necesidades actuales para entender los
            desafíos y oportunidades únicos de tu organización.
          </div>
        </p>
        <p>
          <h2>Selección</h2>
          <div>
            Escogemos las tecnologías y estrategias más adecuadas que se alinean
            con tus objetivos empresariales y proyecciones a futuro.
          </div>
        </p>
        <p className="text-end">
          <h2>Implementación</h2>
          <div>
            Ponemos en marcha las soluciones seleccionadas con precisión y
            cuidado, asegurando una integración fluida con tus operaciones
            existentes.
          </div>
        </p>
        <p>
          <h2>Beneficios</h2>
          <div>
            Observa cómo tu inversión se traduce en mejoras tangibles, mayor
            eficiencia y una ventaja competitiva sostenible.
          </div>
        </p>
      </main>
    </div>
  );
}

export default Timeline;
