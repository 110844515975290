import React from "react";

function SecondSection() {
  return (
    <>
      <div className="about-us-second-section container">
        <div className="d-flex justify-content-center">
          <div className="width-about-us">
            <div className="row">
              <div className="img-col col-xl-6 d-flex align-items-center">
                <img className="team-image" src="/igly_team.png" alt="" />
              </div>
              <div className="col-xl-6 d-flex flex-column justify-content-center align-items-start">
                <div className="width-about-us-p">
                  <h1 className="fw-bold">
                    Un equipo de mentes que trabajan por la innovación de
                    servicios a través de tecnología
                  </h1>
                  <p className="pt-4">
                    Hemos sido atentos a como se mueve el mundo tecnológico
                    durante algunos años y contamos con la colaboración de
                    expertos en diferentes sectores del mercado que han aportado
                    su enfoque profesional para el desarrollo de nuevos
                    productos tecnológicos para el alcance de todos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecondSection;
