import React from "react";

export default function SaaS() {
  return (
    <>
      <div className="saas-section container">
        <div className="pb-5 d-flex flex-column align-items-center justify-content-center">
          <div className="width-saas">
            <div className="bc-saas pb-5">
              <a href="/ourservices" className="text-decoration-none">
                Transformamos la complejidad en simplicidad tecnológica
              </a>{" "}
              <code className="r-arrow px-2">&#9654;</code>
              <span className="fw-bold">SaaS</span>
            </div>
            <h1>¿Qué son los Servicios SaaS?</h1>
            <p className="pt-4">
              Los servicios SaaS, o "Software como Servicio", son una forma
              revolucionaria de acceder a programas y aplicaciones a través de
              internet. Imagina que en lugar de comprar e instalar un software
              en tu computadora o en la red de tu empresa, puedas acceder a él
              simplemente con una conexión a internet.
            </p>
            <div className="my-5 d-flex justify-content-center">
              <img
                className="saas-img-1"
                src="/saas_diagram.png"
                alt=""
                width="1250"
                height="auto"
              ></img>
              <img
                className="saas-img-2"
                src="/saas_img.png"
                alt=""
                width="450"
                height="auto"
              ></img>
            </div>
            <h1 className="pt-4">
              ¿Cómo Benefician los Servicios SaaS a Tu Negocio?
            </h1>
            <ol className="pt-4">
              <li>
                <span className="fw-bold">Facilidad y Accesibilidad:</span> Con
                SaaS, puedes utilizar aplicaciones avanzadas sin necesidad de
                ser un experto en tecnología. Todo lo que necesitas es una
                conexión a internet. Esto significa que puedes acceder a tus
                herramientas de trabajo desde cualquier lugar y en cualquier
                momento, ya sea desde tu oficina, en casa o incluso en
                movimiento.
              </li>
              <li className="pt-3">
                <span className="fw-bold">Costo-Eficiente:</span> Elimina la
                necesidad de grandes inversiones iniciales en software. Pagas
                por lo que usas, generalmente a través de una suscripción, lo
                que ayuda a reducir los costos operativos y de mantenimiento.
              </li>
              <li className="pt-3">
                <span className="fw-bold">Siempre Actualizado:</span> Los
                proveedores de SaaS se encargan de todas las actualizaciones y
                mantenimiento del software. Esto asegura que siempre tendrás
                acceso a las últimas funcionalidades y seguridad sin esfuerzo
                adicional o interrupciones en tu negocio.
              </li>
              <li className="pt-3">
                <span className="fw-bold">Escala con Tu Negocio:</span> SaaS
                ofrece una gran flexibilidad. A medida que tu negocio crece,
                puedes ajustar fácilmente tu suscripción para adaptarte a tus
                nuevas necesidades, sin la necesidad de comprar hardware o
                software adicional. Seguridad y Recuperación: Con SaaS, tus
                datos están almacenados de forma segura en la nube. Esto
                significa que incluso si tu computadora o dispositivos sufren
                daños, tus datos están seguros y accesibles desde cualquier otro
                dispositivo.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}
