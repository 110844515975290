import React from "react";

import "./../../css/Content.scss";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import FourSection from "./FourSection";
import TrustedBy from "./TrustedBy";

function Content() {
  return (
    <>
      <div className="content container">
        <FirstSection />
        <SecondSection />
      </div>
      <FourSection />
      <TrustedBy />
    </>
  );
}

export default Content;
