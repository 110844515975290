import React from "react";

function TrustedBy() {
  return (
    <div className="trusted-by-section d-flex align-items-center justify-content-center w-100">
      <div className="width-trusted-by-section">
        <div className="d-flex justify-content-center flex-column">
          <h1 className="fw-bold">Confiados en por</h1>
          <p className="pt-5">
            Confiados en por Igly, basamos nuestra experiencia en el uso de las
            siguientes tecnologías líderes:
          </p>
          <div className="row py-5">
            <div className="col py-3">
              <img src="/aws.svg" alt="" width="60" height="60"></img>
            </div>
            <div className="col py-3">
              <img src="/node-js.svg" alt="" width="60" height="60"></img>
            </div>
            <div className="col py-3">
              <img src="/java.svg" alt="" width="60" height="60"></img>
            </div>
            <div className="col py-3">
              <img src="/kotlin.svg" alt="" width="60" height="60"></img>
            </div>
            <div className="col py-3">
              <img src="/python.svg" alt="" width="60" height="60"></img>
            </div>
            <div className="col py-3">
              <img src="/docker.svg" alt="" width="60" height="60"></img>
            </div>
            <div className="col py-3">
              <img src="/javascript.svg" alt="" width="60" height="60"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrustedBy;
