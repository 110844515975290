import React, { useState, useEffect } from "react";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./App.scss";
import "./App.css";
import Content from "./components/content/Content";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AboutUs from "./components/aboutus/AboutUs";
import OurServices from "./components/ourservices/OurServices";
import SaaS from "./components/ourservices/SaaS";
import MobileNavBar from "./components/MobileNavBar";

function App() {
  const [mobileMode, setmobileMode] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const mobileSize = window.innerWidth <= 1200;

      if (mobileSize) {
        setmobileMode(true);
      } else {
        setmobileMode(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = (value: boolean) => {
    setmobileMode(value);
  };

  // Utiliza el estado mobileMode para determinar qué navegación mostrar
  return (
    <div id="app-container">
      {mobileMode ? <MobileNavBar toggleMenu={toggleMenu} /> : <NavBar />}
      <div className="container-fluid px-0 d-flex justify-content-center align-items-center flex-column">
        <Router>
          <Routes>
            <Route path="/" element={<Content />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/ourservices" element={<OurServices />} />
            <Route path="/ourservices/saas" element={<SaaS />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
        <Footer />
      </div>
    </div>
  );
}

export default App;
