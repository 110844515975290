import React from "react";
import "../../css/OurServices.scss";
import Timeline from "./Timeline";

function OurServices() {
  return (
    <div className="content container">
      <div className="our-services-first-section pb-5 d-flex flex-column align-items-center justify-content-center">
        <div className="width-introduction">
          <div className="row">
            <div className="col-xl-5 d-flex align-items-center justify-content-center">
              <Timeline />
            </div>
            <div className="introduction col-xl-7 d-flex flex-column justify-content-center align-items-end">
              <h1>Transformamos la complejidad en simplicidad tecnológica</h1>
              <p className="pt-4">
                Emprende el viaje hacia soluciones tecnológicas más simples y
                efectivas. Cuéntanos tus necesidades y juntos exploraremos cómo
                nuestra tecnología puede adaptarse y escalar de acuerdo a tus
                requisitos. Empieza hoy y da el primer paso hacia una innovación
                a tu medida.
              </p>
            </div>
          </div>
          <div className="saas d-flex flex-column justify-content-center">
            <h1>Software como un servicio (SaaS)</h1>
            <p className="pt-4">
              Orquestamos la evolución de su visión en soluciones SaaS
              sofisticadas y escalables. Nuestra gestión integral abarca desde
              la conceptualización hasta la implementación, priorizando la
              armonía entre innovación y funcionalidad empresarial.
              Comprometidos con la excelencia, entregamos software que potencia
              su operatividad y crecimiento.
            </p>
            <ul className="list-unstyled pt-5">
              <li>
                <span className="fw-bold">¿Nuevo en SaaS?</span> Aprenda más
                sobre el modelo Software como Servicio{" "}
                <a href="/ourservices/saas" className="fw-bold">
                  aquí
                </a>
                .
              </li>
            </ul>
          </div>
          <div className="saas-services">
            <h3 className="fw-bold">Nuestros productos SaaS:</h3>
            <div className="row">
              <div className="col pe-5">
                <div className="d-flex flex-column mt-5">
                  <div className="ss p-5">
                    <div className="d-flex justify-content-center">
                      <img
                        src="/MediaFlow.svg"
                        alt=""
                        width="120"
                        height="auto"
                      ></img>
                    </div>
                    <p className="pt-4">
                      Optimiza tu proceso de producción audiovisual. Desde la
                      grabación hasta la edición y almacenamiento, nuestra
                      plataforma maneja todo, permitiéndote enfocarte en la
                      creatividad. Gestiona seguimiento de producción,
                      almacenamiento de archivos, y especificaciones de edición,
                      todo en un solo lugar.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col pe-5">
                <div className="d-flex flex-column mt-5">
                  <div className="ss p-5">
                    <div className="d-flex justify-content-center">
                      <img
                        src="/Ztreamers.svg"
                        alt=""
                        width="190"
                        height="auto"
                      ></img>
                    </div>
                    <p className="pt-5">
                      Simplifica tu capacitación empresarial con nuestra
                      plataforma de streaming. Fácil de usar, Ztreamers te
                      permite crear aulas virtuales, compartir materiales y
                      fomentar la interacción, todo desde un solo lugar. Ideal
                      para empresas que buscan una solución práctica y eficiente
                      para la formación en línea.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services container">
            <h3 className="fw-bold">Atendemos:</h3>
            <div className="row mt-5">
              <div className="col pe-5">
                <h3>Gestión de Proyectos</h3>
                <p className="pt-4">
                  Ofrecer servicios de gestión de proyectos para supervisar el
                  desarrollo y la implementación del software SaaS.
                </p>
              </div>
              <div className="col pe-5">
                <h3>Marketing Digital y SEO</h3>
                <p className="pt-4">
                  Ayudar a promocionar el software SaaS en línea a través de
                  estrategias de marketing digital y optimización de motores de
                  búsqueda.
                </p>
              </div>
              <div className="last-item col pe-5">
                <h3>Capacitación</h3>
                <p className="pt-4">
                  Entrenar a los usuarios finales y al personal técnico para
                  utilizar y administrar el software de manera efectiva.
                </p>
              </div>
            </div>
            <div className="second-row row mt-5">
              <div className="col pe-5">
                <h3>Consultoría</h3>
                <p className="pt-4">
                  Asesorar a las empresas en la transformación digital y cómo el
                  software SaaS puede ser un componente clave en esa estrategia.
                </p>
              </div>
              <div className="col pe-5">
                <h3>Marketing Digital y SEO</h3>
                <p className="pt-4">
                  Ayudar a promocionar el software SaaS en línea a través de
                  estrategias de marketing digital y optimización de motores de
                  búsqueda.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
